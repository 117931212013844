{
  "common": {
    "help": "帮助",
    "sure": "确认",
    "cancel": "取消",
    "skip": "跳过",
    "submit": "提交",
    "agree": "同意",
    "refresh": "刷新",
    "viewDetail": "查看详情",
    "newki": "新奇站",
    "searchGoods": "搜索商品",
    "addCar": "加入购物车",
    "buyNow": "立即购买",
    "vip": "品友",
    "newVip": "新品友",
    "apply": "开通",
    "check": "查看",
    "default": "默认",
    "save": "保存",
    "save&use": "保存并使用",
    "delete": "删除",
    "confirm": "请确认",
    "finish": "完成",
    "backHome": "回到首页",
    "backHome3": "回首页",
    "checkOrder": "查看订单",
    "edit": "编辑",
    "remove": "移除",
    "pullRefresh": "下拉即可刷新内容~",
    "pullLoosing": "松开即可刷新内容~",
    "loading": "加载中……",
    "noMore": "没有更多啦~",
    "copy": "复制",
    "copySuccess": "已复制",
    "copyError": "复制失败",
    "rule": "规则",
    "changeSuccess": "修改成功",
    "selectGoodsEmpty": "请先选择商品",
    "select": "选择",
    "recommend": "为您推荐",
    "close": "关闭",
    "wechatAuthError": "微信授权失败",
    "vipPrice": "品友价",
    "login": "登录",
    "total": "总计",
    "remark": "备注",
    "siteUnavailable": "当前地区不可用",
    "retryLater": "出错了，请稍后重试~",
    "siteSelectTitle": "选择地区",
    "chat": "联系客服",
    "saySomething": "说点什么...",
    "sureApply": "确认申请",
    "cancelApply": "取消申请",
    "noContent": "暂无内容~",
    "somethingWentWrong": "出错啦~",
    "searchPlaceholder": "商品搜索",
    "copyRight": "新奇站 版权所有",
    "noData": "暂无数据",
    "visitor": "游客",
    "tips": "温馨提示",
    "abnormalAction": "操作异常，请联系客服",
    "newAndPresell": "新品预售"
  },
  "login": {
    "welcome": "欢迎登录新奇站",
    "inputPhoneNumber": "请输入手机号",
    "inputRightPhoneNumber": "请输入正确的手机号",
    "inputSmsCode": "请输入6位手机短信验证码",
    "getSmsCode": "获取验证码",
    "seconds": "秒",
    "login": "立即登录/注册",
    "loginNow": "立即登录",
    "loginPwd": "密码登录",
    "loginSms": "验证码登录/注册",
    "loginTip": "未注册时验证码登录后将自动创建新奇站账号",
    "ServiceAgreement": "《服务协议》",
    "PrivacyAgreement": "《隐私协议》",
    "agreementTip": "请阅读并勾选同意",
    "loginAgreement": "登录即代表同意",
    "logging": "登录中...",
    "smsError": "验证码错误，请重新输入~",
    "setPwd": "设置密码",
    "findPwd": "找回密码",
    "graphicCode": "请输入图形验证码",
    "graphicRightCode": "请输入正确的图形验证码",
    "setPwdAgain": "请再次确认密码",
    "setPwdError": "两次密码不一致，请重新输入",
    "pwdVerify": "请输入8-20位密码，含字母、数字",
    "pwdError": "密码错误，请重新输入~",
    "inputPwd": "请输入密码",
    "smsExpire": "验证码已失效，请重新获取",
    "chooseAreaCode": "请选择手机区号",
    "chooseLanguage": "请选择语言",
    "chooseSite": "请选择要访问的地区",
    "finishPicVerify": "向右拖动完成拼图",
    "SecurityVerification": "安全验证",
    "error:": "登录过期，请重新登录",
    "loginFloat": "登录开启新奇购物",
    "loginAgain": "修改成功，请重新登录！",
    "thirdLogin": "第三方登录",
    "bind": "绑定手机",
    "bind-tip": "请绑定您现在使用的手机号码，这样新奇站可以为您提供更好的品友服务。",
    "cancelRegister": "取消注册"
  },
  "site": {
    "selectSite": "请选择要访问的地区",
    "selectLanguage": "请选择语言",
    "selectSiteFirst": "请先选择要访问的地区！",
    "areaCodeError": "该地区未设置区号代码，请联系客服！"
  },
  "delivery": {
    "title": "交收方式",
    "onLine": "速递送货",
    "offLine": "门市自取",
    "takeWay": "顺丰自提"
  },
  "goods": {
    "spec": "规格",
    "change": "换购",
    "discountInfo": "共{num}件，优惠价：",
    "pkg": "精选套装",
    "book": "立即预约",
    "booked": "已预约",
    "offState": "商品已下架",
    "offStorage": "地区无货,请切换地址",
    "buyCount": "购买数量",
    "limitCount": "限购{num}件",
    "orderWay": {
      "online": "速递送货",
      "onlineDesc": "送货到家，省心省力，轻松享受购物好时光！",
      "take": "顺丰自提",
      "takeDesc": "顺丰自提，随时取件，更贴心的服务就在身边！",
      "offLine": "门市自取",
      "offLineDesc": "门店提货，现场体验再付款，最快1小时可取！"
    },
    "weight": "重量：{weight}kg",
    "volume": "体积：{volume}cm³",
    "bindBuy": "请先添加数量~",
    "error": "该商品不存在~",
    "inSelected": "已选",
    "package": "精选套装",
    "view": "查看"
  },
  "address": {
    "distribution": "送至",
    "listTitle": "地址管理",
    "add": "新增地址",
    "edit": "编辑地址",
    "receiver": "收货人",
    "receiverValid": "请输入收货人信息",
    "phone": "手机号码",
    "phoneValid": "请输入手机号码",
    "areInfo": "所在地区",
    "areInfoValid": "请选择所在地区",
    "address": "详细地址",
    "addressValid": "请输入详细地址",
    "setDefault": "设为默认",
    "delete": "确认删除该地址？",
    "deleteSuccess": "删除成功",
    "saveSuccess": "添加成功",
    "errorUrl": "无效的地址",
    "exceed": "地址超出配送范围，请修改地址！",
    "noThisAddress": "地址不存在~"
  },
  "promotion": {
    "unit": "件",
    "exclusivePrice": "专属价",
    "price": "当前购买价",
    "originPrice": "原价",
    "vipTip": "开通品友即可享受",
    "promotionTip": "限时优惠",
    "pkgName": "推荐套装",
    "start": "距离开始",
    "end": "距离结束",
    "reduce": "立减",
    "discount": "礼品",
    "calc": "计算中",
    "bind": "一起买，更优惠",
    "countChange": "满{num}件换购",
    "countChangeDetail": "购买本商品<span class='discount-text'>满{num}件</span>，可<span class='discount-text'>选礼品{count}件</span>",
    "changeDetail": "购买本商品，可<span class='discount-text'>换购{count}件</span>",
    "priceChange": "满{num}换购",
    "priceChangeDetail": "订单<span class='discount-text'>满{num}</span>，可<span class='discount-text'>选礼品{count}件</span>",
    "countGift": "满{num}件赠券",
    "countGiftDetail": "购买本商品<span class='discount-text'>满{num}件</span>，支付订单后系统自动发放以下优惠券",
    "priceGift": "满{num}赠券",
    "priceGiftDetail": "购买本商品<span class='discount-text'>满{num}</span>，支付订单后系统自动发放以下优惠券",
    "coupon": "赠券",
    "couponDetail": "购买本商品，支付订单后系统自动发放以下优惠券",
    "change": "选礼品",
    "changeTitle": "可选礼品",
    "allDiscount": "满{num}享{count}折",
    "allDiscountDetail": "购买<span class='discount-text'>满{num}</span>，可享<span class='discount-text'>{count}折</span>",
    "allReduce": "满{num}减{count}",
    "allReduceDetail": "购买<span class='discount-text'>满{num}</span>，可立减<span class='discount-text'>{count}</span>",
    "bindQtyDiscount": "满{num}件{count}折",
    "bindQtyDiscountDetail": "购买本商品<span class='discount-text'>满{num}件</span>，每件商品可享<span class='discount-text'>{count}折</span>",
    "bindQtyReduce": "满{num}件每件减{count}",
    "bindQtyReduceDetail": "购买本商品<span class='discount-text'>满{num}件</span>，每件商品可立减<span class='discount-text'>{count}</span>",
    "bindPriceDiscount": "满{num}享{count}折",
    "bindPriceDiscountDetail": "购买本商品<span class='discount-text'>满{num}</span>，可享<span class='discount-text'>{count}折</span>",
    "bindPriceReduce": "满{num}件减{count}",
    "bindPriceReduceDetail": "购买本商品<span class='discount-text'>满{num}</span>，可立减<span class='discount-text'>{count}</span>",
    "singleDiscount": "每件享{count}折",
    "singleDiscountDetail": "购买本商品每件可享<span class='discount-text'>{count}折</span>",
    "singleReduce": "每件减{count}",
    "singleReduceDetail": "购买本商品每件立减<span class='discount-text'>{count}</span>",
    "changeSelect": "可选礼品",
    "order": {
      "amount": "商品金额",
      "vipDiscount": "品友折扣",
      "vipBenefit": "品友优惠",
      "freightDiscount": "运费优惠",
      "discount": "促销优惠",
      "vipCoupon": "品友券",
      "coupon": "优惠券",
      "couponText": "券",
      "freightCoupon": "运费券",
      "reduce": "随机立减",
      "reduceText": "减",
      "buyAmount": "应付款",
      "freight": "运",
      "changeDiscount": "惠",
      "original": "原订单抵扣",
      "changeText": "换"
    },
    "moreDiscount": "获取更优价",
    "morePromotion": "更多优惠",
    "changeGoods": "礼品",
    "giftError": "已超过可选换购品最大数量，请重新选择~",
    "changeGoodsNotSelectYet": "您有礼品未选择~"
  },
  "coupon": {
    "discount": "<span class='coupon-discount'>{num}</span>折",
    "reduce": "减<span class='coupon-discount'>{num}</span>",
    "special": "指定商品可用",
    "all": "全场通用",
    "vip": "专属券",
    "freightName": "运费券",
    "limit": "满{num}可用",
    "freight": "用于订单运费抵扣",
    "use": "立即使用",
    "receive": "立即领取",
    "gift": "赠{num}张",
    "allDate": "永久有效",
    "dynamicDate": "{num}天后过期",
    "noCoupon": "无可用券",
    "hasCoupon": "有{num}张可用，请选择",
    "modalTitle": "新奇优惠券",
    "able": "可用优惠券",
    "disable": "不适用优惠券",
    "coupon": "优惠券",
    "exchange": "立即兑换",
    "noUse": "暂无可用",
    "member": "品友券",
    "exchangeCoupon": "品礼券",
    "exchangeCouponA": "品礼兑换券"
  },
  "search": {
    "newGoods": "新品",
    "search": "搜索",
    "normal": "综合",
    "count": "销量",
    "new": "上新",
    "category": "品类",
    "history": "搜索历史",
    "hot": "热门搜索",
    "defaultPlaceHolder": "支持搜索商品、订单",
    "urlError": "跳转地址不符合url规则",
    "goodsPlaceholder": "请输入要搜索的商品名称",
    "goodsOrderPlaceholder": "请输入要搜索的订单商品",
    "historyEmpty": "浏览记录为空~",
    "orderEmpty": "您还没有下过单哦~",
    "collectEmpty": "您的收藏为空",
    "cartEmpty": "购物车为空",
    "historySearchEmpty": "没找到合适的商品~",
    "orderSearchEmpty": "你还未买过当前商品~"
  },
  "number": {
    "thousand": "千",
    "tenThousand": "万",
    "hundredMillion": "亿"
  },
  "track": {
    "track": "包裹",
    "list": "包裹列表",
    "trackInfo": "快递信息",
    "listEmpty": "暂无包裹信息~",
    "order": "已下单",
    "orderMeta": "商品已下单",
    "stock": "商品备货中",
    "stockMeta": "商品备货出库中",
    "package": "已发货",
    "packageMeta": "商品已经打包，等待揽收",
    "done": "已完成",
    "doneMeta": "商品已经签收，欢迎下次观临",
    "transit": "运输中",
    "pickup": "派送中",
    "delivered": "已签收",
    "waitTake": "待交收",
    "takeDone": "已交收",
    "view": "查看"
  },
  "order": {
    "order": "订单",
    "list": "订单列表",
    "tabs": {
      "all": "全部",
      "payment": "待支付",
      "progress": "待处理",
      "done": "待交收",
      "sharing": "待分享"
    },
    "status": {
      "status": "订单状态",
      "cancel": "已取消",
      "payment": "待支付",
      "paySure": "支付确认中",
      "payError": "支付异常",
      "payNotAll": "部分付款",
      "handle": "门店备货中",
      "storageHandle": "仓库备货中",
      "waitTake": "待取货",
      "waitReceive": "待收货",
      "finish": "已完成"
    },
    "payError": "检测到支付异常，订单未完成支付~",
    "paySure": "支付到账确认中，请耐心等候~",
    "payNotAll": "订单仅部份付款，请及时支付尾款~",
    "btnGroup": {
      "pay": "支付",
      "payAgain": "重新支付",
      "payResidue": "支付尾款",
      "payContinue": "继续支付",
      "addCart": "加入购物车",
      "checkTrack": "查看物流",
      "sure": "确认收货",
      "chat": "联系客服",
      "share": "分享体验",
      "shareContinue": "继续分享",
      "afterSale": "申请售后",
      "cancelOrder": "取消订单",
      "afterSaleSearch": "售后查询",
      "sureDialog": "确认已经收到订单商品？",
      "sureDoAfterSale": "确认申请售后？",
      "instruction": "使用说明",
      "afterSaleQty": "已退/换<span>{num}</span>件",
      "loadMoreErpOrder": "点击查询更多订单"
    },
    "cancel": {
      "title": "取消原因",
      "sureAgain": "请再次确认是否取消当前订单？"
    },
    "error": "订单不存在~",
    "noSameUser": "当前登录的账号与收货信息不一致",
    "finishAndWellcome": "您的订单已完成，欢迎下次光临~"
  },
  "orderDetail": {
    "statusDesc": {
      "cancel": "您的订单已取消\n如有支付款项将于1~3个工作日退回\n欢迎下次光临~",
      "finish": "您的订单已完成，欢迎下次光临~",
      "handle": "您的订单正在备货中\n请耐心等待~",
      "payNotAll": "您的转账金额少于订单应付金额！\n请及时支付尾款~",
      "payError": "您的转账信息有误，请重新支付~",
      "paySureOnline": "支付到账确认中\n请耐心等候~",
      "paySureOffline": "您的转账信息核对中，\n预计1~3个工作日完成~\n如上传凭证信息有误 ，请及时修改~",
      "payment": "超时后订单将自动取消\n已取消订单无法恢复，请及时支付~"
    },
    "delivery": {
      "onLine": "速递送货",
      "offLine": "门市自取",
      "takeWay": "顺丰自提"
    },
    "order": {
      "no": "订单号",
      "createTime": "下单时间",
      "payTime": "支付时间",
      "payWay": "支付方式",
      "paySn": "支付单号",
      "amount": "商品金额",
      "freight": "运费",
      "paid": "实付款",
      "shouldPaid": "应付款",
      "codAmount": "货到付款金额"
    },
    "storeOrder": {
      "no": "订单号",
      "sourceBillNo": "来源订单号",
      "createTime": "购买时间",
      "standardAmount": "商品金额",
      "discountAmount": "优惠金额",
      "payAmount": "实付金额"
    },
    "showMore": "查看更多",
    "payProof": "支付凭证",
    "morePkg": "订单分多个包裹发出",
    "takeCode": "取货码",
    "takeTip": "请于 {time} 前往指定门店取货~",
    "remark": "订单留言",
    "vip": {
      "name": "新奇站品友",
      "disable": "已失效",
      "able": "待生效",
      "time": "有效期至：{time}"
    },
    "vipName": "品友",
    "integral": "获得积分"
  },
  "buy": {
    "title": "确认订单",
    "similar": "相似订单未支付，并且已使用优惠券！",
    "similarJump": "操作",
    "addAddress": "请添加地址！",
    "selectAddress": "地址列表",
    "selectShop": "选择门市",
    "shop": "门市",
    "shopEmpty": "请选择取货门店！",
    "remark": "订单留言，最多100字",
    "submit": "提交订单",
    "total": "合计",
    "discount": "已优惠",
    "explain": "价格说明",
    "manualBillTips": {
      "tips": "温馨提示",
      "noSameUser": "当前登录的账户与收货信息不一致 \n 是否切换为登录账户",
      "confirm": "确认",
      "cancel": "取消",
      "errorContact": "因输入错误次数较多，该订单已锁定 \n 请通过下单平台联系客服 \n 或添加客服WhatsApp账户",
      "contact": "复制 WhatsApp",
      "copySuccess": "复制成功",
      "backHome": "回首页",
      "billError": "订单失效了，请联系客服小蓝帮你重新下单"
    },
    "site": {
      "infoTip": "信息提示",
      "infoDesc": "当前登入站点与订单提供站点信息不一致，请确认是否继续下单或切换站点~",
      "goon": "继续下单",
      "change": "切换到{name}",
      "chat": "联系客服",
      "languageError": "该地区未配置语言，切换失败"
    },
    "vip": {
      "able": "本单开通品友已省",
      "disable": "开通品友，本单预计可省",
      "check": "品友专属权益待生效~"
    },
    "deliveryTypeError": "获取交收方式有误",
    "addressIdError": "请填写交收信息",
    "exceedError": "地址超出配送范围，请修改地址",
    "noLoginSubimitErrorTips": "请先校验交收资料"
  },
  "sendWay": {
    "1": "速递送货",
    "2": "门市自取",
    "3": "顺丰自提",
    "maskBtn": "点击校验交收资料",
    "loginBtn": "登录查看详情",
    "selectCabinet": "请选择自提点！"
  },
  "pay": {
    "title": "收银台",
    "vipPrice": "包含品友购买  {price}/年",
    "pay": "支付",
    "scan": "扫码支付",
    "app": "请使用支付APP扫码支付",
    "paying": "支付中",
    "cancel": "订单已取消",
    "promotionTime": "促销优惠，剩余支付时间",
    "payTime": "剩余支付时间",
    "levelMessage": "家D好品仅差一步！\n确认不继续吗？",
    "waitPay": "稍后支付",
    "payGoing": "继续支付",
    "payConfirm": "1、已支付请点击【我已支付】，并耐心等待支付结果，请勿重复支付！\n2、如需更换支付方式，请点击【去支付】",
    "goPay": "去支付",
    "payDone": "我已支付",
    "success": {
      "finish": "支付完成",
      "search": "支付中...",
      "sendCoupon": "新奇小蓝给您送券啦~",
      "sendCouponTip": "订单确认收货后系统自动赠送",
      "searchTip": "1、由于网络原因，当前支付结果未能及时获得\n2、如确认已经支付，请耐心等待或联系客服\n3、如您未支付，请点击重新支付",
      "backApp": "返回「新奇站」APP",
      "downloadApp": "下载「新奇站」APP",
      "backTip": "如返回失败，请手动打开新奇站APP",
      "searchAppTip": "1、由于网络原因，当前支付结果未能及时获得\n2、请返回「新奇站」APP等待支付结果"
    },
    "amount": "金额",
    "error": "支付异常，请选择其他支付方式！"
  },
  "cart": {
    "cart": "购物车",
    "invalid": "失效商品",
    "otherSite": "其它站点商品",
    "selectAll": "全选",
    "change": "礼品",
    "changeText": "点击立享更多礼品",
    "amount": "合计",
    "discount": "已优惠",
    "priceExplain": "价格说明",
    "buy": "结算",
    "collect": "收藏",
    "addCollect": "加入收藏",
    "removeGoods": "移除商品",
    "gift": "换购",
    "empty": "购物车没有商品哦~",
    "addSuccess": "添加成功！",
    "confirmDelete": "确定删除这些商品吗？",
    "limit": "限购：{num}",
    "inventory": "库存：{num}",
    "cancelCollect": "取消收藏",
    "sureRemove": "确认移除该列表商品吗？",
    "sureCancelCollect": "确认取消收藏选中商品吗？",
    "success": "加入购物车成功"
  },
  "nav": {
    "home": "首页",
    "category": "品类",
    "newki": "家品历",
    "shop": "购物",
    "mine": "我的"
  },
  "applyVip": {
    "apply": "开通品友更优惠",
    "discount": "本单已省",
    "forecast": "本单预计可省",
    "desc": "全站会员价",
    "desc1": "(非会员商品除外)",
    "agree": "本人已阅读并同意",
    "agreement": "《新奇站增值会员用户协议》",
    "couponNum": "品友券X{num}",
    "goodsExchangeNum": "品礼券X{num}",
    "freightNum": "运费券X{num}",
    "exchangeNum": "品礼券X{num}",
    "memberNum": "品友券X{num}",
    "monthNum": "每月{num}张",
    "special": "会员专属",
    "noLimit": "无门槛",
    "limit": "满{limit}可用",
    "hasLimit": "指定商品可用",
    "activity": "VIP活动",
    "gift": "奇趣礼品",
    "more": "更多惊喜",
    "noDiscount": "无专属优惠",
    "year": "年"
  },
  "transfer": {
    "tip1": "1、成功过数后，请点击页面下方【上传过数凭证】按钮，上传过数成功的图片资料。",
    "tip2": "2、Newki 将在一个工作日进行资料核对，核对完成后为你提供商品的寄送服务。",
    "transferInfo": "转账信息",
    "hide": "收起",
    "show": "查看",
    "payImage": "提交成功过数截图",
    "uploadPayImage": "上传过数凭证",
    "changePayWay": "变更支付方式",
    "chat": "联系客服",
    "payTeaching": "付款教程",
    "submit": "确认提交",
    "outTradeNoTip": "请复制以下内容至转账页面备注处。"
  },
  "fileUpload": {
    "sizeError": "请上传小于{size}M的文件",
    "typeError": "请上传{type}类型的文件",
    "numError": "请上传少于{num}个的文件",
    "uploading": "上传中...",
    "failed": "上传失败"
  },
  "setting": {
    "title": "设置",
    "info": "个人信息",
    "address": "收货地址管理",
    "pwd": "修改密码",
    "newki": "关于新奇站",
    "registerAgreement": "用户注册协议",
    "agreement": "隐私政策",
    "logout": "退出"
  },
  "update": {
    "pwd": {
      "title": "修改密码",
      "updateBySms": "通过手机验证码修改",
      "updateByPwd": "通过旧密码修改",
      "submit": "确认修改",
      "cancel": "取消修改",
      "oldPwd": "请输入当前登入密码"
    },
    "phone": "修改手机号码",
    "nickname": "修改昵称",
    "nicknameLabel": "昵称",
    "nicknamePlaceholder": "请输入昵称",
    "email": "修改邮箱",
    "emailLabel": "邮箱",
    "emailPlaceholder": "请输入您的邮箱地址",
    "emailErrorTips": "请输入正确的邮箱地址"
  },
  "history": {
    "title": "浏览记录",
    "empty": "暂无浏览记录",
    "addCart": "加入购物车",
    "addCollect": "加入收藏"
  },
  "integral": {
    "title": "我的积分",
    "canUse": "可用积分",
    "total": "总积分",
    "frozen": "冻结积分",
    "frozenExplain": "正常交易后获得的积分，会有7天的冻结期，之后会变更为可用积分进行使用",
    "empty": "暂无积分使用明细~",
    "normal":"正常交易",
    "marketingActivity": "营销活动",
    "integralActivity": "积分活动",
    "integralOverDue": "积分过期",
    "integralOffset": "积分抵现",
    "cancelOffset": "取消抵现",
    "integralExchange": "积分兑换",
    "manualAdjustment": "人工调整",
    "integralClear": "积分清零",
    "integralLock": "积分锁定",
    "integralUnlock": "积分释放"
  },
  "coupons": {
    "title": "优惠券",
    "notUse": "待使用",
    "used": "已使用",
    "failed": "已过期",
    "empty": "该分类没有优惠券~"
  },
  "my": {
    "mine": "我的",
    "notLogin": "未登入",
    "registerDay": "新奇体验已<span class='blue-text'>{day}</span>天",
    "all": "全部",
    "more": "更多",
    "order": {
      "title": "我的订单",
      "titleTabs": {
        "site": "官网订单",
        "shop": "门店订单"
      },
      "notPay": "待付款",
      "deal": "待处理",
      "makeSure": "待交收",
      "waitShare": "待分享",
      "afterSale": "售后"
    },
    "myPresell": "我的预约",
    "history": "浏览记录",
    "share": "分享",
    "myShare": "我的分享",
    "myCollect": "我的收藏",
    "view": "近期浏览",
    "integral": "积分",
    "coupon": "优惠券",
    "collectEmpty": "你还未收藏任何内容喔,<br/>快<span class='blue-text'>去逛逛</span>吧~",
    "presellEmpty": "你还未参与任何新品预售哦,<br/>快<span class='blue-text'>去逛逛</span>吧~",
    "shareEmpty": "你还未发布任何内容喔,<br/>快<span class='blue-text'>去分享</span>吧~",
    "viewEmpty": "你还未浏览过新奇商品喔,<br/>快<span class='blue-text'>去逛逛</span>吧~"
  },
  "vipPackage": {
    "discount": "累积已省",
    "integral": "我的积分",
    "doubleIntegral": "双倍积分",
    "integralDiscount": "积分优惠",
    "present": "奇趣礼品",
    "more": "更多惊喜",
    "gift": "品友礼物",
    "active": "专属活动",
    "month": "每月",
    "week": "每周",
    "year": "每年",
    "day": "每日",
    "coupon": "品友券",
    "freight": "运费券",
    "goodsExchange": "品礼券",
    "couponDes": "专属优惠券",
    "freightDes": "运费抵扣券",
    "goodsExchangeDes": "品礼兑换券",
    "vipPrice": "全站会员价",
    "send": "赠送",
    "unit": "张",
    "onlyVip": "仅会员商品",
    "discountReduce": "低至8.5折",
    "noLimit": "无门槛",
    "exclusive": "专属",
    "member": "品友券"
  },
  "error": {
    "pageNotFount": "当前页面不存在",
    "pageJump": "页面将在",
    "pageJump1": "秒后自动跳转"
  },
  "share": {
    "title": "体验分享",
    "share": "分享体验",
    "shareTip": "欢迎写下您的新奇体验…",
    "shareGoods": "体验过的新奇商品",
    "select": "请选择",
    "myShare": "我的分享",
    "myShareEmpty": "暂时没有分享哦~",
    "myLike": "我的点赞",
    "myLikeEmpty": "暂时没有点赞哦~",
    "textEmpty": "请填写分享的内容~",
    "imageEmpty": "请上传分享的图片~"
  },
  "userInfo": {
    "title": "个人信息",
    "phone": "手机号码",
    "nickname": "昵称",
    "email": "邮箱"
  },
  "emailPopup": {
    "title": "请填写邮箱用于账单服务",
    "agreement": "《支付服务说明》",
    "errorTips": "请输入正确的邮箱地址"
  },
  "phonePopup": {
    "title": "请填写落单手机号",
    "errorTips": "手机号码错误，请重试",
    "diffTips": "收货手机号码错误，请重试"
  },
  "sharePopup": {
    "title": "立即分享给好友",
    "link": "复制链接"
  },
  "collect": {
    "success": "加入收藏成功",
    "deleSuccess": "取消收藏成功"
  },
  "newki": {
    "entry": "进入推荐内容",
    "month": "月",
    "monthly": "家家新品未?!",
    "monthlyYet": "今月你家佐未?!",
    "monthlyAdd": "家D",
    "fit": "宜"
  },
  "afterSale": {
    "status": "状态",
    "list": "售后列表",
    "history": "售后记录",
    "typeSheetTitle": "售后服务申请",
    "detailApply": "售后服务申请",
    "areasonPopupSheetTitle": "申请原因",
    "deliveryTypeText": "与原订单保持一致",
    "errMessage": {
      "title": "错误提示",
      "backHome": "回首页",
      "chat": "联系客服",
      "text": "1、当前登录账户与售后单信息不匹配；<br />2、如有疑问请联系客服；<br />3、页面将于 5 秒后跳转。",
      "textTime": "1、当前登录账户与售后单信息不匹配；<br />2、如有疑问请联系客服；<br />3、页面将于 {time} 秒后跳转。"
    },
    "billInfo": {
      "1or3Days": "商品退回，平台收货并且检测无误后将进行退款，预计1~3个工作日。",
      "onProcessAndThen": "换货商品退回后，平台收货并确认检测无误后，将生成新的换货订单，如换货商品存在差价或运费需前向换货单支付。",
      "orderNo": "订单编号",
      "exchangeOrder": "换货订单",
      "underHandle": "待生成",
      "exchangeDeliveryAmount": "换货运费",
      "freeShipping": "平台包邮",
      "serviceOrderNo": "服务单号",
      "serviceType": "服务类型",
      "applyTime": "申请时间",
      "refundAmount": "退款金额",
      "refundWay": "退款方式",
      "theSameWay": "原路返回",
      "score": "积分返还",
      "applyReson": "申请原因",
      "resonDetail": "具体原因",
      "voucher": "凭证",
      "platformAudit": "平台审核"
    },
    "dotLine": {
      "subimtApply": "提交申请",
      "waitingForAudit": "待审核",
      "waitingForPay": "待支付",
      "waitingForReturn": "待退回",
      "underRepair": "维修中",
      "waitingForHandle": "待处理",
      "done": "已完成",
      "cancel": "已取消",
      "statueText": {
        "type1": {
            "name": "退款",
            "status1": {
                "name": "待审核",
                "text1": "已提交申请",
                "text2": "平台审核中，请耐心等待"
            },
            "status4": {
                "name": "待处理",
                "text1": "审核通过",
                "text2": "平台跟进处理中"
            },
            "status6": {
                "name": "已完成",
                "text1": "已完成",
                "text2": "如有退款，将在1-3个工作日原路退回，注意查收"
            },
            "status7": {
                "name": "已取消",
                "text1": "已取消",
                "text2": " "
            }
        },
        "type3": {
            "name": "退货退款",
            "status1": {
                "name": "待审核",
                "text1": "已提交申请",
                "text2": "平台审核中，请耐心等待"
            },
            "status3": {
                "name": "待退回",
                "text1": "等待商品寄/送回中",
                "text2": "请按页面提示将商品退回"
            },
            "status4": {
                "name": "待处理",
                "text1": "平台已收到商品",
                "text2": "跟进处理中"
            },
            "status6": {
                "name": "已完成",
                "text1": "已完成",
                "text2": "退款1-3个工作日原支付退回，注意查收~"
            },
            "status7": {
                "name": "已取消",
                "text1": "已取消",
                "text2": " "
            }
        },
        "type4": {
            "name": "换货",
            "status1": {
                "name": "待审核",
                "text1": "已提交申请",
                "text2": "平台审核中，请耐心等待"
            },
            "status3": {
                "name": "待退回",
                "text1": "等待商品寄/送回中",
                "text2": "请按页面提示将商品退回"
            },
            "status4": {
                "name": "待处理",
                "text1": "平台已收到商品",
                "text2": "跟进处理中"
            },
            "status6": {
                "name": "已完成",
                "text1": "已完成",
                "text2": "换新订单已经生成，请注意查收~"
            },
            "status7": {
                "name": "已取消",
                "text1": "已取消",
                "text2": " "
            }
        },
        "type5": {
            "name": "维修",
            "status1": {
                "name": "待审核",
                "text1": "已提交申请",
                "text2": "平台审核中，请耐心等待"
            },
            "status2": {
                "name": "待支付",
                "text1": "请先支付本次维修费用",
                "text2": ""
            },
            "status3": {
                "name": "待退回",
                "text1": "等待商品寄/送回中",
                "text2": ""
            },
            "status5": {
                "name": "维修中",
                "text1": "平台已收到商品",
                "text2": "跟进维修中，请耐心等待~"
            },
            "status6": {
                "name": "已完成",
                "text1": "已完成",
                "text2": "换新订单已经生成，请注意查收~"
            },
            "status7": {
                "name": "已取消",
                "text1": "已取消",
                "text2": " "
            }
        }
    }
    },
    "toastTip": {
      "applySuccess": "创建成功~",
      "amountTips": "金额必须大于0",
      "selectReasonTips": "请选择申请原因",
      "selectShopTips": "请选择自取门市地址~",
      "noBillData": "记录不存在",
      "getOrderTypeFail": "获取订单退款类型失败"
    },
    "refund": {
      "tips1": "1、退款后促销优惠将一并取消。",
      "tips2": "2、部份退款不支持优惠券退回。",
      "tips3": "3、如有优惠券退回时，请注意券有效期。",
      "tips4": "4、订单退款后，本服务无法撤消。"
    },
    "refundGoods": {
      "tips1": "1、退货后促销优惠将一并取消。",
      "tips2": "2、部份退货不支持优惠券退回。",
      "tips3": "3、如有优惠券退回时，请注意券有效期。",
      "tips4": "4、订单退货寄回后，本服务无法撤消。"
    },
    "exchange": {
      "tips1": "1、订单退货寄回后，本服务无法撤消。",
      "tips2": "2、建议在申请换货前先与平台客服沟通。"
    },
    "category": {
      "type": "服务类型",
      "refundAmount": "退款金额",
      "refundAmountTips": "具体退款金额以平台审核为准",
      "refundDeliveryAmount": "退还运费",
      "refundTo": "退款方式",
      "theSameWay": "原路返回",
      "applyReason": "申请原因",
      "selectReason": "请选择申请原因",
      "uploadImageOrVideo": "请您描述具体问题并上传商品图片/视频",
      "uploadPlaceHolder": "请描述售后申请具体原因",
    },
    "returnWay": {
      "tips1": "1、请选择换货商品的交收方式。",
      "tips2": "2、换货商品返还时可能需要支付运费，以平台审核结果为准。",
      "theSameWay": "交收方式与原订单保持一致",
      "name": "交收方式",
      "refundDeliveryAmount": "送回运费",
      "platformTips": "平台审核为准",
      "payTips": "换货订单生成后，前往换货单支付"
    }
  },
  "afterSaleDetail": {
    "title": "售后服务详情",
    "applyLog": "售后记录",
    "viewLog": "查看",
    "deliveryBillNoInput": "请输入快递单号",
    "deliveryBillNo": "快递单号",
    "noRecord": "记录不存在",
    "deliveryCompany": "快递公司",
    "toastTips": {
      "makeSure": "请确认",
      "readMeFirst": "请先阅读售后服务单内容 \n 确认申请后再进行操作",
      "cancelConfirm": "请再次确认是否取消？",
      "cancelSuccess": "取消申请成功~",
      "makeSureSuccess": "确认成功~",
      "networdError": "网络错误~",
      "noAllowAfterConfirmed": "确认退回方式后，不允许更改~",
      "makeSureToCancel": "确定取消售后申请~",
      "noRecord": "记录不存在",
      "refreshPage": "请刷新页面",
      "invalidPageAndRefresh": "页面信息已经失效，请刷新页面~",
      "refresh": "刷新"
    },
    "copyInfo": {
      "receiver": "收货人",
      "phone": "手机号码",
      "address": "地址"
    },
    "returnWay": {
      "note": "请留意，这些操作将有助于我们更快为您提供售后服务。非常感谢您的合作。",
      "online": {
        "confirm": {
          "title": "请确认申请并将商品寄回指定地址",
          "tips1": "包裹内需附上一张纸条，填写订单编号、退货原因等相关资讯。",
          "tips2": "将包裹寄出后，填写快递资讯至页面。",
          "tips3": "如有赠品请同时寄回。"
        },
        "confirmAndDo": {
          "title": "为确保售后服务，请您完成以下操作",
          "tips1": "包裹内需附上一张纸条，填写订单编号、退货原因等相关资讯。",
          "tips2": "将包裹寄出后，填写快递资讯至页面。",
          "tips3": "如有赠品请同时寄回。",
        }
      },
      "offline": {
        "title": "为确保售后服务，请您按以下步骤操作：",
        "tips1": "将需退回的商品送至以下门店，如有赠品请一并送回。",
        "tips2": "到门店后，请出示本页面给店员。"
      }
    },
    "returnWayRadio": {
      "title": "退回方式",
      "online": "寄回",
      "offline": "送回",
      "category": {
        "deleveryAmout": "支付运费",
        "needToPay": "需您支付",
        "noNeedToPay": "无需支付",
        "payToWorkerTips": "需您在寄件时支付对应运费给收件人员",
        "platformPayment": "平台支付",
        "paymentByPlatform": "本次运费由新奇站平台支付，您无需支付",
        "deliveryCompany": "快递公司",
        "selectCompany": "请选择寄回的快递公司",
        "deliveryNo": "快递单号",
        "receiver": "收货人",
        "goodsReturnToAddress": "请将商品寄回以下地址",
        "goodsReturnToShop": "请将商品送回以下门市",
        "goodsReturnToAddressShort": "商品寄回以下地址",
        "shop": "门市"
      }
    },
    "stockInfo": "无法获取库存信息，请稍候刷新页面重试",
    "goodsInfo": {
      "title": "售后商品",
      "exchangeTitle": "换货商品",
      "noAllow": {
        "step1": "订单退货寄回后，本服务无法撤消。",
        "step2": "建议在申请换货前先与平台客服沟通。"
      },
      "applyRefundTips": {
        "step1": "退款后促销优惠将一并取消。",
        "step2": "部份退款不支持优惠券退回。",
        "step3": "如有优惠券退回时，请注意券有效期。",
        "step4": "订单退款后，本服务无法撤消。"
      },
      "applyReturnTips": {
        "step1": "退货后促销优惠将一并取消。",
        "step2": "部份退货不支持优惠券退回。",
        "step3": "如有优惠券退回时，请注意券有效期。",
        "step4": "订单退货寄回后，本服务无法撤消。"
      },
    }
  },
  "afterSaleType": {
    "refund": "退款",
    "returnGoods": "退货",
    "exchange": "换货",
    "maintenance": "维修"
  },
  "article": {
    "title": "文章详情",
    "error": "未传入正确的文章ID或使用场景！",
    "empty": "该文章暂无内容~",
  },
  "activity": {
    "fail": "活动已过期",
    "end": "活动已结束",
    "integral": "当前积分",
    "num": "可换次数",
    "change": "立即兑换",
    "join": "立即参与",
    "record": "兑换记录",
    "recordEmpty": "暂无兑换记录~",
    "invite": "邀请记录",
    "inviteEmpty": "暂无邀请记录，快分享给好友吧~",
    "rule": "活动规则",
    "memberDay": "可体验会员权益{num}个月",
    "price": "价值",
    "view": "去查看",
    "congratulations": "恭喜用户",
    "receive": "获得",
    "joined": "您已参与过该活动~",
    "gain": "获得",
    "coupon": "商品券"
  },
  "chat": {
    "sendOrder": "发送订单",
    "goods": "发送商品",
    "searchOrder": "查询订单",
    "title": "新奇站正在为您服务",
    "sendTip": "说点什么",
    "noMoreMsg": "--没有更多消息--",
    "loading": "加载中...",
    "error": "消息获取失败！",
    "sendEmpty": "发送消息不能为空~",
    "addAsk": "添加咨询",
    "loginAsk": "登入咨询",
    "newkiAsk": "新奇小蓝咨询",
    "navTitle": "很高兴为您服务，家家新品未?!"
  },
  "cabinet": {
    "title": "取货信息",
    "type": {
      "sfStation": {
        "name": "顺丰站",
        "tips": "顺丰服务站，适用于各类合规货物的收发"
      },
      "smartLocker": {
        "name": "智能柜",
        "tips": "适用于较小货物的存放，凭短信码自助取件"
      },
      "servicePoint": {
        "name": "营业点",
        "tips": "顺丰合作便利店，适用于各类货物的收件"
      }
    },

    "pleaseSelect": "请选择",

    "userName": "收货人",
    "inputUserName": "请输入收货人信息",
    "phoneNum": "手机号码",
    "inputPhoneNum": "请输入正确的手机号",
    "addPickUpPointer": "新增取货点",
    "addTips": "请新增取货点",
    "selectAddressDetail": "请选择货柜地址",

    "selectTitle": "选择取货地址",
    "inputAddressOrCode": "请输入地址或者编码",
    "pickupPointerType": "自提点类型",
    "selectPickPointer": "请选择自提点类型",
    "pickupPointerArea": "所在地区",
    "selectArea": "请选择所在地区",
    "detailAddress": "详细地址",

    "searchTips": "请输入地址或编码",
    "unavailable": "商品超出格口限制，不可用",
    "invalid": "该地址已失效，请重选",

    "search": "搜索",
    "next": "下一步",
    "save": "保存",
    "saveTips": "请填写取货信息",
    "saveAndUse": "保存并使用",
    "emptyTips": "没有找到相关智能柜<br/>试试别的关键词",
    "emptyAreaTips": "该区域下无数据，请重新选择~"
  },
  "comment": {
    "noComment": "暂无评论~",
    "total": "共<span class='sum'>{total}</span>条评论",
    "noMore": "没有更多了",
    "reply": "回复",
    "open": "展开",
    "count": "条回复",
    "openMore": "展开更多",
    "close": "收起",
  },
  "instruction": {
    "title": "使用说明",
    "invalidData": "贴文数据有误",
    "noData": "无说明书数据"
  },
  "product": {
    "goods": "商品",
    "detail": "详情",
    "goodsDetail": "商品详情",
    "noGoodsDetail": "暂无商品详情哦~",
    "share": "体验",
    "goodShare": "商品体验"
  },
  "render": {
    "title": "内容组件多语言",
    "button": {
      "buyNow": "立即开通 <span class='buy-now'>{price}</span> /年",
      "tips": "限时优惠",
      "agree": "本人已阅读并同意",
      "articleTitle": "《新奇站增值会员用户协议》",
      "chat": "联系客服",
      "goPay": "继续支付"
    },
    "promotion": {
      "buyVipNow": "支付到账确认中<br/>如上传凭证信息有误 ，请及时修改~",
      "partialPayment": "您的转账金额少于订单应付金额！<br/>请及时支付尾款~",
      "billError": "您的转账信息有误，请重新支付~",
      "inEffect": "品友权益生效中~",
      "exist": "您有订单加购了品友，完成支付后可享有权益",
      "unit": "件"
    },
    "hotspot": {
      "hotArea": "热区",
      "action": "动作"
    },
    "newHot": {
      "title": "新品热品"
    },
    "review": {
      "title": "品味评测"
    },
    "share": {
      "title": "体验分享"
    },
    "video": {
      "title": "视频"
    }
  },
  "errorPage": {
    "updating": "系统升级维护中",
    "tryLater": "请稍后再试",
    "excuse": "造成不便，敬请谅解",
    "refresh": "刷新"
  },
  "like": {
    "submitSuccess": "点赞成功~",
    "submitFail": "取消成功~"
  },
  "qrCode": {
    "error": {
      "title": "二维码生成错误",
      "tips": "NewkiQrCode二维码生成错误"
    }
  },
  "category": {
    "newGoods": "新品",
    "hotGoods": "热品"
  },
  "presale": {
    "title": "预约记录",
    "empty": "暂无预约记录"
  }
}
